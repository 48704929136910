
.dapps-landing, .dapps-signin-landing {
    max-width: 90vw;
    text-align: center;

    > * {
        padding: 1em;
    }
    
    > img {
        width: 160px;
        height: 160px;
    }

    > h1, > h2, > .dapps-landing-arrow {
        width: fit-content;
        margin: auto;
    }

    .dapps-landing-panel {
        
        margin: 1em;
        padding: 1em;
        border-radius: 0.25em;

        color: black;
        background-color: white;
    }

    .dapps-landing-arrow {
        width: 0;
        height: 0;
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        
        border-top: 20px solid gold;

    }
}


.dapps-landing-panel {
    > h3 {
        width: fit-content;
        margin:auto;
    }
    
    > .apps {
        display: flex;
        flex-direction: column;

        > .flex-1 {
            flex: 1;
        }
    }
}