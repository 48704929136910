
.price-ticker-button {
    padding: 0px 15px 1px;
    margin-right: 8px;

    width: fit-content;

    height: 40px;
    border-radius: var(--w3m-button-border-radius);
    color: var(--w3m-accent-fill-color);
    background-color: var(--w3m-accent-color);


    display: grid;
    place-items: center;
    
    cursor: pointer;
}

.price-ticker {

    > .base-selection {
        text-align: center;
    }

    > .exchange-rates {
        // display:flex;
        // flex-direction: row;
        
        // > .flex-1 {
        //     flex: 1;
        // }

        // > .numeral {
        //     text-align: right;
        // }

        // > .symbol {
        //     text-align: right;
            
        // }

        display: grid;
        grid-template-columns: 1fr 1fr 1fr 0.25em 1fr 1fr;
        place-items: center;

        > .numeral {
            width: 100%;
            text-align: right;
        }

        > .decimal {
            width: 100%;
            text-align: left;
        }
    }
    
    > h3 {
        text-align: center;
    }
    
    > .currency-selection {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        place-items: center;

        > div {
            cursor: pointer;
            padding: 1em;
            background-color: green;

            border-radius: 1.5em;

            &[highlighted=true] {
                box-shadow: 0 0 0.5em 0.25em lightskyblue;
            }
        }
    }
}

.dapps-switch {
    display: inline-block;

    margin: 0px;
    padding: 0px;

    width: 2em;
    height: 1em;

    position: relative;

    .left-side, .right-side, .circle {
        position: absolute;
        top: 0em;
        left: 0em;

        // margin: 0.5em;
        padding: 0.5em;

        height: 0px;

        border-radius: 0.5em;
    }

    .circle {

        // padding: 0.75em;
        // border-radius: 0.75em;

        width: 0px;
    }

    .circle {
        transition: left 0.25s ease;
    }

    .left-side {
        transition: width 0.25s ease;
    }

    .left-side { background-color: red; }
    .right-side { background-color: #00FF00; }
    .circle { background-color: blue; }

    .right-side {
        width: 1em;
    }
}

.dapps-switch.off {
    .left-side {
        width: 0em;
    }
}

.dapps-switch.on {
    .left-side {
        width: 1em;
    }

    .circle {
        left: 1em;
    }
}