
@import "/global.scss";


$header-height: 64px;
$footer-height: 256px;

$black-background-text: #EEEEEE;
$white-background-text: black;

$background-1: #111111;
$background-2: #161616;
$background-3: #222222;
$background-4: #272727;

$dapps-content-background-color: #161616AA;

html,
body,
#root {
    width: 100%;
    height: 100%;
    margin: 0px;
}

.dapps-template {
    // background-color: $background-3;
    color: $black-background-text;
}

.dapps-template-body {
    position: relative;

    background-color: $dark-mode-light-background;

    border: solid $dark-mode-border 0px;
    border-bottom-width: 1px;

    width: 100%;
    height: calc(100%);
    min-height: calc(100vh - $header-height);

    display: flex;
    flex-direction: column;
}

.dapps-header {
    display: flex;
    flex-direction: row;

    background-color: $dark-mode-background;

    border: solid $dark-mode-border 0px;
    border-bottom-width: 1px;

    height: $header-height;

    * {
        margin: auto;
    }

    .dapps-logo {
        width: calc($header-height - 0.25em);
        height: calc($header-height - 0.25em);
        padding: 0.5em;
        
        .dapps-logo-img {
            width: calc(100% - 0.5em);
            height: calc(100% - 0.5em);
        }
    }

    > .dapps-dropdown {
        

        display: grid;
        place-items: center;

        // width: 32px;
        // background-color: green;
        border-radius: var(--w3m-button-border-radius);

        height: 40px;
        background-color: green;
        padding: 0 15px 1px;
        padding-left: 0px;
        padding-right: 0px;
        margin-right: 8px;

        width: fit-content;

        > .dapps-button {
            padding: 0px;
            border-radius: var(--w3m-button-border-radius);
            // color: var(--w3m-accent-fill-color);
            // background-color: var(--w3m-accent-color);
            padding: 0 15px 1px;

            // width: fit-content;
            // height: fit-content;
        }
    }
}

.dapps-dropdown {
    position: relative;

    >.dapps-button {
        background-color: green;
    }

    >.dapps-dropdown-container {
        width: max-content;
        display: none;
        background-color: $background-2;
    }

    >#dapps-dropdown-checkbox {
        display: none;
    }

    >#dapps-dropdown-checkbox:checked+.dapps-dropdown-container {
        display: block;
        position: absolute;
    }
}

.dapps-menu {
    position: absolute;

    z-index: 1;

    background-color: $dapps-content-background-color;

    height: max-content;

    width: max-content;
    // left: auto;
    right: 100%;

    &[open] {
        right: auto;
    }

    transition: right 0.25s;


}

.dapps-menu-item {
    > a{
        font-weight: bold;
        color: white;
    }

    margin: 1em;
}

.dapps-login {
    >.dapps-button {
        background-color: green;
    }
}

.dapps-footer {
    display: flex;
    flex-direction: row;

    background-color: $dark-mode-background;

    >div {
        flex: 1;
    }

    >.dapps-footer-links {
        width: 33%;
    }
}

.dapps-connector {
    margin-right: 8px;
}

.dapps-footer-meta {
    height: 100%;
    margin: auto;
    vertical-align: middle;
}

.dapps-footer-links {
    a {
        display: block;
        color: white;
        margin: 0.5em;
    }
}

.dapps-content {
    margin: auto;

    max-width: max-content;
    padding: 1em;

    border: solid $dark-mode-border 1px;
    border-radius: 1em;
    
    color: black;
    background-color: white;
    
}


.dapps-button {
    border-radius: 1.5em;
    padding: 1em;
    font-weight: bold;
    width: max-content;
    background-color: green;
    cursor: pointer;

    &[disabled] {
        cursor: not-allowed;
        background-color: darkgrey;
    }
}

.dapps-app-button {
    display: block;

    border-radius: 0.25em;
    margin: 1em;
    padding: 1em;
    font-weight: bold;
    font-size: larger;

    color: white;

    text-decoration: none;
    color: inherit;

    background-color: green;
    &:hover {
        background-color: gold;
        color: green;
    }

    // &.dapps-app-link {
    //     background-color: lightgreen;
    //     color: black;

    //     &:hover {
    //         background-color: gold;
    //         color: darkgreen;
    //     }
    // }
}

.dapps-gas-tax-visualizer {

    > h4, > .rate {
        width: fit-content;
        margin: auto;
        padding: 0.5em;
    }
    
    > .gtv-table {
        padding: 1em;
        display: flex;
        flex-direction: row;
    
        > div {
            display: flex;
            flex-direction: column;

            > div {
                height: 1.2em;
            }
        }
    
    
        .gtv-description {
            width: 40%;
        }
    
        .gtv-sign {
            flex: auto;
        }
    
        .gtv-whole {
            text-align: right;
        }
    
        .gtv-decimal>div,
        .gtv-fraction>div,
        .gtv-currency>div {
            width: min-content;
        }
    
        .gtv-fraction {
            width: 15%;
        }
    
        .gtv-currency {
            width: 12.5%;
            text-align: right;
        }
    }
    


}

.dapps-quote-visualizer {
    > * {
        width: fit-content;
        margin: auto;
        padding: 0.25em;
    }
}

.transaction-details {
    display:flex;
    flex-direction: column;

    height: 100%;
    
    > * {
        // width: max-content;
        margin: auto;
        text-align: center;
    }
}

.flex1 {
    flex: 1;
}

.flex2 {
    flex: 2;
}


.dapps-background {
    position: fixed;

    z-index: -1;

    > video {
        min-width: 100vw;
        min-height: 100vh;
        
        max-height: 100%;
    }
}


.first-visit-redirect {
    width: 100%;
    // height: 100%;

    max-width: 360px;
    
    // display: grid;
    // place-items: center;
    text-align: center;

    > .dapps-app-button {
        width: max-content;
        // margin: 2em auto;

        // scale: 1.5
        margin: auto;
    }

    > .dapps-wallet-button {
        margin: 2em auto;
        width: max-content;
        scale: 1.5;
    }
}

.wallet-links {
    margin: 1em;
    border-radius: 0.25em;

    > div {
        width: fit-content;
        margin: auto;
    }

    a {
        width: 96px;
        height: 96px;

        padding: 0px;

        // border: solid black 1px;
        border-radius: 2em;
        box-shadow: 0px 0px 4px black;

        display: inline-block;
    }

    .metamask-link svg, .trust-wallet-link svg {
        width: 96px;
        height: 96px;

        // padding
    }
}

// .metamask-vertical-svg {
    .st0{display:none;}
	.st1{fill:#F5841F;}
	.st2{fill:#E27625;}
	.st3{fill:#D7C1B3;}
	.st4{fill:#2F343B;}
	.st5{fill:#CC6228;}
	.st6{fill:#C0AD9E;}
	.st7{fill:#763E1A;}
	.st8{fill:#161616;}
// }

.dapps-overflow {
    text-align: center;
    
    margin-top: 2em;
    margin-bottom: 2em;

    > * {
        width: fit-content;
        margin: auto;
        margin-top: 2em;
        margin-bottom: 2em;
    }
}

.dapps-tap-through {
    text-align:center;
    width: fit-content;

    > .content {
        padding: 1em;
    }

    > .controls {
        display:flex;
        flex-direction: row;

        > .dapps-button {
            flex:1
        }
    }
}

.help-content {
    max-width: 300px;
    // min-width: 300px;
    // max-width: 600px;

    text-align: center;
    margin: auto;

    > svg {
        font-size: 1.5em;
    }
}

.dapps-wallet-button {
    padding: 0px 15px 1px;
    margin-right: 8px;

    height: 40px;
    border-radius: var(--w3m-button-border-radius);
    color: var(--w3m-accent-fill-color);
    background-color: var(--w3m-accent-color);

    display: grid;
    place-items: center;

    cursor: pointer;
}

.dapps-wallet {
    padding: 1em;

    > h2 {
        text-align: center;
    }
}

.token-visualizer {
    display: flex;
    flex-direction: row;

    padding: 0.5em 0em;

    > img {
        width: 64px;
        height: 64px;
        padding-right: 1em;
    }

    > div {
        display: flex;
        flex-direction: column;

        > div {
            text-align: right;
        }
    }
}