.dapps-landing, .dapps-signin-landing {
  text-align: center;
  max-width: 90vw;
}

.dapps-landing > *, .dapps-signin-landing > * {
  padding: 1em;
}

.dapps-landing > img, .dapps-signin-landing > img {
  width: 160px;
  height: 160px;
}

.dapps-landing > h1, .dapps-landing > h2, .dapps-landing > .dapps-landing-arrow, .dapps-signin-landing > h1, .dapps-signin-landing > h2, .dapps-signin-landing > .dapps-landing-arrow {
  width: fit-content;
  margin: auto;
}

.dapps-landing .dapps-landing-panel, .dapps-signin-landing .dapps-landing-panel {
  color: #000;
  background-color: #fff;
  border-radius: .25em;
  margin: 1em;
  padding: 1em;
}

.dapps-landing .dapps-landing-arrow, .dapps-signin-landing .dapps-landing-arrow {
  border-top: 20px solid gold;
  border-left: 20px solid #0000;
  border-right: 20px solid #0000;
  width: 0;
  height: 0;
}

.dapps-landing-panel > h3 {
  width: fit-content;
  margin: auto;
}

.dapps-landing-panel > .apps {
  flex-direction: column;
  display: flex;
}

.dapps-landing-panel > .apps > .flex-1 {
  flex: 1;
}

body {
  background-color: #181818;
}

html, body, #root {
  width: 100%;
  height: 100%;
  margin: 0;
}

.dapps-template {
  color: #eee;
}

.dapps-template-body {
  background-color: #1f1f1f;
  border: 0 solid #333;
  border-bottom-width: 1px;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 64px);
  display: flex;
  position: relative;
}

.dapps-header {
  background-color: #181818;
  border: 0 solid #333;
  border-bottom-width: 1px;
  flex-direction: row;
  height: 64px;
  display: flex;
}

.dapps-header * {
  margin: auto;
}

.dapps-header .dapps-logo {
  width: calc(64px - .25em);
  height: calc(64px - .25em);
  padding: .5em;
}

.dapps-header .dapps-logo .dapps-logo-img {
  width: calc(100% - .5em);
  height: calc(100% - .5em);
}

.dapps-header > .dapps-dropdown {
  border-radius: var(--w3m-button-border-radius);
  background-color: green;
  place-items: center;
  width: fit-content;
  height: 40px;
  margin-right: 8px;
  padding: 0 0 1px;
  display: grid;
}

.dapps-header > .dapps-dropdown > .dapps-button {
  border-radius: var(--w3m-button-border-radius);
  padding: 0 15px 1px;
}

.dapps-dropdown {
  position: relative;
}

.dapps-dropdown > .dapps-button {
  background-color: green;
}

.dapps-dropdown > .dapps-dropdown-container {
  background-color: #161616;
  width: max-content;
  display: none;
}

.dapps-dropdown > #dapps-dropdown-checkbox {
  display: none;
}

.dapps-dropdown > #dapps-dropdown-checkbox:checked + .dapps-dropdown-container {
  display: block;
  position: absolute;
}

.dapps-menu {
  z-index: 1;
  background-color: #161616aa;
  width: max-content;
  height: max-content;
  transition: right .25s;
  position: absolute;
  right: 100%;
}

.dapps-menu[open] {
  right: auto;
}

.dapps-menu-item {
  margin: 1em;
}

.dapps-menu-item > a {
  color: #fff;
  font-weight: bold;
}

.dapps-login > .dapps-button {
  background-color: green;
}

.dapps-footer {
  background-color: #181818;
  flex-direction: row;
  display: flex;
}

.dapps-footer > div {
  flex: 1;
}

.dapps-footer > .dapps-footer-links {
  width: 33%;
}

.dapps-connector {
  margin-right: 8px;
}

.dapps-footer-meta {
  vertical-align: middle;
  height: 100%;
  margin: auto;
}

.dapps-footer-links a {
  color: #fff;
  margin: .5em;
  display: block;
}

.dapps-content {
  color: #000;
  background-color: #fff;
  border: 1px solid #333;
  border-radius: 1em;
  max-width: max-content;
  margin: auto;
  padding: 1em;
}

.dapps-button {
  cursor: pointer;
  background-color: green;
  border-radius: 1.5em;
  width: max-content;
  padding: 1em;
  font-weight: bold;
}

.dapps-button[disabled] {
  cursor: not-allowed;
  background-color: #a9a9a9;
}

.dapps-app-button {
  color: inherit;
  background-color: green;
  border-radius: .25em;
  margin: 1em;
  padding: 1em;
  font-size: larger;
  font-weight: bold;
  text-decoration: none;
  display: block;
}

.dapps-app-button:hover {
  color: green;
  background-color: gold;
}

.dapps-gas-tax-visualizer > h4, .dapps-gas-tax-visualizer > .rate {
  width: fit-content;
  margin: auto;
  padding: .5em;
}

.dapps-gas-tax-visualizer > .gtv-table {
  flex-direction: row;
  padding: 1em;
  display: flex;
}

.dapps-gas-tax-visualizer > .gtv-table > div {
  flex-direction: column;
  display: flex;
}

.dapps-gas-tax-visualizer > .gtv-table > div > div {
  height: 1.2em;
}

.dapps-gas-tax-visualizer > .gtv-table .gtv-description {
  width: 40%;
}

.dapps-gas-tax-visualizer > .gtv-table .gtv-sign {
  flex: auto;
}

.dapps-gas-tax-visualizer > .gtv-table .gtv-whole {
  text-align: right;
}

.dapps-gas-tax-visualizer > .gtv-table .gtv-decimal > div, .dapps-gas-tax-visualizer > .gtv-table .gtv-fraction > div, .dapps-gas-tax-visualizer > .gtv-table .gtv-currency > div {
  width: min-content;
}

.dapps-gas-tax-visualizer > .gtv-table .gtv-fraction {
  width: 15%;
}

.dapps-gas-tax-visualizer > .gtv-table .gtv-currency {
  text-align: right;
  width: 12.5%;
}

.dapps-quote-visualizer > * {
  width: fit-content;
  margin: auto;
  padding: .25em;
}

.transaction-details {
  flex-direction: column;
  height: 100%;
  display: flex;
}

.transaction-details > * {
  text-align: center;
  margin: auto;
}

.flex1 {
  flex: 1;
}

.flex2 {
  flex: 2;
}

.dapps-background {
  z-index: -1;
  position: fixed;
}

.dapps-background > video {
  min-width: 100vw;
  min-height: 100vh;
  max-height: 100%;
}

.first-visit-redirect {
  text-align: center;
  width: 100%;
  max-width: 360px;
}

.first-visit-redirect > .dapps-app-button {
  width: max-content;
  margin: auto;
}

.first-visit-redirect > .dapps-wallet-button {
  width: max-content;
  margin: 2em auto;
  scale: 1.5;
}

.wallet-links {
  border-radius: .25em;
  margin: 1em;
}

.wallet-links > div {
  width: fit-content;
  margin: auto;
}

.wallet-links a {
  border-radius: 2em;
  width: 96px;
  height: 96px;
  padding: 0;
  display: inline-block;
  box-shadow: 0 0 4px #000;
}

.wallet-links .metamask-link svg, .wallet-links .trust-wallet-link svg {
  width: 96px;
  height: 96px;
}

.st0 {
  display: none;
}

.st1 {
  fill: #f5841f;
}

.st2 {
  fill: #e27625;
}

.st3 {
  fill: #d7c1b3;
}

.st4 {
  fill: #2f343b;
}

.st5 {
  fill: #cc6228;
}

.st6 {
  fill: #c0ad9e;
}

.st7 {
  fill: #763e1a;
}

.st8 {
  fill: #161616;
}

.dapps-overflow {
  text-align: center;
  margin-top: 2em;
  margin-bottom: 2em;
}

.dapps-overflow > * {
  width: fit-content;
  margin: 2em auto;
}

.dapps-tap-through {
  text-align: center;
  width: fit-content;
}

.dapps-tap-through > .content {
  padding: 1em;
}

.dapps-tap-through > .controls {
  flex-direction: row;
  display: flex;
}

.dapps-tap-through > .controls > .dapps-button {
  flex: 1;
}

.help-content {
  text-align: center;
  max-width: 300px;
  margin: auto;
}

.help-content > svg {
  font-size: 1.5em;
}

.dapps-wallet-button {
  border-radius: var(--w3m-button-border-radius);
  color: var(--w3m-accent-fill-color);
  background-color: var(--w3m-accent-color);
  cursor: pointer;
  place-items: center;
  height: 40px;
  margin-right: 8px;
  padding: 0 15px 1px;
  display: grid;
}

.dapps-wallet {
  padding: 1em;
}

.dapps-wallet > h2 {
  text-align: center;
}

.token-visualizer {
  flex-direction: row;
  padding: .5em 0;
  display: flex;
}

.token-visualizer > img {
  width: 64px;
  height: 64px;
  padding-right: 1em;
}

.token-visualizer > div {
  flex-direction: column;
  display: flex;
}

.token-visualizer > div > div {
  text-align: right;
}

.overlay {
  z-index: 10;
  place-items: center;
  width: 100vw;
  height: 100vh;
  display: grid;
  position: fixed;
  top: 0;
  left: 0;
  overflow: scroll;
}

.overlay > .overlay-background {
  filter: blur();
  opacity: .5;
  background-color: #000;
  width: 100%;
  height: 100%;
  position: absolute;
}

.overlay > .overlay-content {
  background-color: #fff;
  border-radius: 1em;
  max-width: calc(100vw - 4em);
  max-height: calc(100vh - 4em);
  padding: 1em;
  position: relative;
  overflow: scroll;
}

.overlay[animation="show"] .overlay-background {
  opacity: .5;
  animation: .25s ease-in show-overlay-background;
}

.overlay[animation="show"] .overlay-content {
  opacity: 1;
  animation: .25s ease-in show-overlay-background;
}

.overlay[animation="hide"] .overlay-background {
  opacity: 0;
  animation: .25s ease-out hide-overlay-background;
}

.overlay[animation="hide"] .overlay-content {
  opacity: 0;
  animation: .25s ease-out hide-overlay-content;
}

.overlay[animation="display-none"] {
  display: none;
}

@keyframes show-overlay-background {
  0% {
    opacity: 0;
  }

  100% {
    opacity: .5;
  }
}

@keyframes show-overlay-content {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes hide-overlay-background {
  0% {
    opacity: .5;
  }

  100% {
    opacity: 0;
  }
}

@keyframes hide-overlay-content {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.notifier {
  z-index: 1;
  flex-direction: column;
  height: 100vh;
  margin: 0;
  padding: 0;
  display: flex;
  position: fixed;
  left: 100%;
}

.notifier .notification-spacer {
  flex: 1;
}

.notifier .notification {
  float: bottom;
  border-radius: .5em;
  width: 256px;
  margin: 1em;
  padding: 1em 2em 1em 1em;
  position: relative;
  left: calc(-256px - 5em);
}

.notifier .notification .close {
  cursor: pointer;
  width: min-content;
  height: min-content;
  position: absolute;
  top: .25em;
  left: calc(100% - 1.25em);
}

.notifier .notification[animation="show"] {
  background-color: #eee;
  animation: .25s ease-in show-notification;
}

.notifier .notification[animation="hide"] {
  opacity: 0;
  animation: .25s linear hide-notification;
}

@keyframes show-notification {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
    background: #eee;
  }
}

@keyframes hide-notification {
  0% {
    opacity: 1;
    background: #eee;
  }

  50% {
    opacity: 0;
  }

  100% {
    height: 0;
    margin: 0;
    padding: 0;
  }
}

.price-ticker-button {
  border-radius: var(--w3m-button-border-radius);
  color: var(--w3m-accent-fill-color);
  background-color: var(--w3m-accent-color);
  cursor: pointer;
  place-items: center;
  width: fit-content;
  height: 40px;
  margin-right: 8px;
  padding: 0 15px 1px;
  display: grid;
}

.price-ticker > .base-selection {
  text-align: center;
}

.price-ticker > .exchange-rates {
  grid-template-columns: 1fr 1fr 1fr .25em 1fr 1fr;
  place-items: center;
  display: grid;
}

.price-ticker > .exchange-rates > .numeral {
  text-align: right;
  width: 100%;
}

.price-ticker > .exchange-rates > .decimal {
  text-align: left;
  width: 100%;
}

.price-ticker > h3 {
  text-align: center;
}

.price-ticker > .currency-selection {
  grid-template-columns: repeat(3, 1fr);
  place-items: center;
  display: grid;
}

.price-ticker > .currency-selection > div {
  cursor: pointer;
  background-color: green;
  border-radius: 1.5em;
  padding: 1em;
}

.price-ticker > .currency-selection > div[highlighted="true"] {
  box-shadow: 0 0 .5em .25em #87cefa;
}

.dapps-switch {
  width: 2em;
  height: 1em;
  margin: 0;
  padding: 0;
  display: inline-block;
  position: relative;
}

.dapps-switch .left-side, .dapps-switch .right-side, .dapps-switch .circle {
  border-radius: .5em;
  height: 0;
  padding: .5em;
  position: absolute;
  top: 0;
  left: 0;
}

.dapps-switch .circle {
  width: 0;
  transition: left .25s;
}

.dapps-switch .left-side {
  background-color: red;
  transition: width .25s;
}

.dapps-switch .right-side {
  background-color: #0f0;
}

.dapps-switch .circle {
  background-color: #00f;
}

.dapps-switch .right-side {
  width: 1em;
}

.dapps-switch.off .left-side {
  width: 0;
}

.dapps-switch.on .left-side {
  width: 1em;
}

.dapps-switch.on .circle {
  left: 1em;
}
/*# sourceMappingURL=index.f79e8f53.css.map */
