
.notifier {
    position: fixed;
    z-index: 1;

    margin: 0px;
    padding: 0px;

    left: 100%;
    height: 100vh;

    display: flex;
    flex-direction: column;

    .notification-spacer {
        flex: 1;
    }

    .notification {
        position: relative;

        float: bottom;

        width: 256px;
        left: calc(-256px - 5em);

        margin: 1em;
        padding: 1em;
        padding-right: 2em;

        border-radius: 0.5em;
        

        .close {
            width: min-content;
            height: min-content;
            position: absolute;

            top: 0.25em;
            left: calc(100% - 1.25em);

            cursor: pointer;

        }

        &[animation=show] {
            animation: 0.25s ease-in 0s show-notification;
            background-color: #EEEEEE;
        }

        &[animation=hide] {
            animation: 0.25s linear 0s hide-notification;
            opacity: 0%;
        }
    }
}

@keyframes show-notification {
    0% {
        opacity: 0%;
    }

    100% {
        background: #EEEEEE;
        opacity: 100%;
    }
}

@keyframes hide-notification {
    0% {
        background: #EEEEEE;
        opacity: 100%;
    }

    50% {
        opacity: 0%;
    }

    100% {
        margin: 0px;
        padding: 0px;
        height: 0px;
    }
}
