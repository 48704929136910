

$background-opacity: 50%;

.overlay {
    position: fixed;
    z-index: 10;

    display: grid;
    place-items: center;

    width: 100vw;
    height: 100vh;

    top: 0px;
    left: 0px;

    overflow: scroll;

    > .overlay-background {
        filter: blur();
        background-color: black;
        opacity: $background-opacity;

        width: 100%;
        height: 100%;

        position: absolute;
    }

    > .overlay-content {

        position: relative;

        max-width: 90vw;
        max-height: 90vh;
        
        max-width: calc(100vw - 4em);
        max-height: calc(100vh - 4em);
        
        background-color: white;

        border-radius: 1em;
        padding: 1em;

        overflow: scroll;
    }

    &[animation=show] {
        .overlay-background {
            animation: 0.25s ease-in 0s show-overlay-background;
            opacity: $background-opacity;
        }

        .overlay-content {
            animation: 0.25s ease-in 0s show-overlay-background;
            opacity: 100%;
        }
    }

    &[animation=hide] {
        .overlay-background {
            animation: 0.25s ease-out 0s hide-overlay-background;
            opacity: 0%;
        }

        .overlay-content {
            animation: 0.25s ease-out 0s hide-overlay-content;
            opacity: 0%;
        }
    }

    &[animation=display-none] {
        display: none;
    }
}

@keyframes show-overlay-background {
    0% {
        opacity: 0%;
    }

    100% {
        opacity: $background-opacity;
    }
}

@keyframes show-overlay-content {
    0% {
        opacity: 0%;
    }

    100% {
        opacity: 100%;
    }
}

@keyframes hide-overlay-background {
    0% {
        opacity: $background-opacity;
    }

    100% {
        opacity: 0%;
    }
}

@keyframes hide-overlay-content {
    0% {
        opacity: 100%;
    }

    100% {
        opacity: 0%;
    }
}